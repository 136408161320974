<template>
  <button
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex flex-col text-left"
    @click="goToJustification"
  >
    <div class="flex flex-1 items-center justify-start gap-2 w-full">
      <div class="w-2/5 md:w-1/5">
        <div class="flex-shrink-0 font-medium flex items-center gap-1">
          <fw-icon-arrow-right class="w-5 h-5 text-gray-500" />
          <span class="font-semibold">#{{ item.key }}</span>
        </div>
      </div>
      <div class="w-1/5 text-sm text-gray-500 font-medium capitalize flex gap-3">
        <v-clamp class="text-sm" autoresize :max-lines="1">
          {{ item.motive ? $t(`motives.${item.motive}`) : 'Não definido' }}
        </v-clamp>
      </div>
      <div class="md:flex w-2/5 text-xs text-gray-500 font-normal gap-2 items-center justify-between">
        <div class="truncate overflow-clip">
          <fw-tag v-for="(date, i) in item.dates" :key="i" size="xs" type="xlight">
            {{ date.start_at | formatDate }} - {{ date.end_at | formatDate }}
          </fw-tag>
        </div>

        <div class="inline-flex gap-2">
          <span v-if="item.start_time">{{ `2000-01-01 ${item.start_time}` | formatTime }}h</span>
          <span v-if="item.start_time && item.end_time">-</span>
          <span v-if="item.end_time">{{ `2000-01-01 ${item.end_time}` | formatTime }}h</span>
        </div>
      </div>
      <div class="md:w-1/5">
        <div
          :class="[colors[item.status]]"
          class="inline-flex h-7 px-3 py-1 rounded-lg bg-opacity-20 text-xs text-opacity-90 font-medium items-center"
        >
          <span>{{ $t(`status.${item.status}`) }}</span>
          <fw-icon-check v-if="item.approved === true" class="w-4 h-4 ml-1" />
        </div>
      </div>
      <div v-if="manager" class="hidden md:flex w-1/6 gap-2 items-center">
        <fw-avatar size="xs" :user="manager" class="flex-shrink-0" />
        <v-clamp class="font-semibold text-sm" autoresize :max-lines="1">
          {{ manager.display_name || manager.full_name }}
        </v-clamp>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    users: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      colors: {
        draft: 'bg-yellow-300',
        submitted: 'bg-teal-300',
        reviewed: 'bg-teal-400',
        manager_reviewed: 'bg-teal-400',
        closed: 'bg-gray-200'
      }
    }
  },

  computed: {
    manager() {
      if (this.users && this.item.manager) {
        return this.users[this.item.manager]
      }

      return null
    }
  },

  methods: {
    goToJustification() {
      return this.$router.push({
        name: 'manage-absence-justification',
        params: { key: this.item.key }
      })
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "motives": {
      "wedding": "Casamento",
      "deathOfRelativeOrKin": "Falecimento de familiar ou afins",
      "nonAttributableFacts": "Factos não imputáveis ao/à trabalhador/a",
      "medical": "Pela necessidade de tratamento ambulatório, realização de consultas médicas ou exames complementares de diagnóstico",
      "educationalSituationOfMinor": "Deslocação à escola para se inteirar da situação educativa de filho/a menor",
      "firstDayOfSchool": " Acompanhamento de filho/a menor de 12 anos no primeiro dia de escola",
      "bloodDonationFirstAid": "Doação de sangue e socorrismo",
      "procedure": "Necessidade de submissão a métodos de seleção em procedimento concursal",
      "electedWorkers": "Trabalhadores/as eleitos/as para estruturas de representação coletiva",
      "publicOfficeCandidate": "Candidatos/as a eleições para cargos públicos durante o período legal da campanha eleitoral",
      "prenatalConsultations": "Dispensa para consultas pré-natais",
      "timeCreditUse": "Gozo de crédito de horas do mês anterior",
      "externalService": "Serviço externo no(s) dia(s)",
      "occasionalInterruptions": "Interrupções ocasionais",
      "annualCredit": "Crédito Anual",
      "other": "Outras faltas previstas na Lei"
    },
    "status": {
      "label": "Estado",
      "draft": "Rascunho",
      "submitted": "Submetida",
      "approved": "Aprovada",
      "evaluated": "Avaliada",
      "denied": "Rejeitada",
      "reviewed": "Revisto",
      "manager_reviewed": "Revisto pelo responsável",
      "closed": "Fechada"
    }
  },
  "en": {
    "motives": {
      "wedding": "Wedding",
      "deathOfRelativeOrKin": "Death of relative or kin",
      "nonAttributableFacts": "Factors not attributable to the employee",
      "medical": "For the need for outpatient treatment, medical appointments, or complementary diagnostic tests",
      "educationalSituationOfMinor": "Trip to the school to find out about the educational situation of a minor child",
      "firstDayOfSchool": "Accompaniment of a child under 12 on the first day of school",
      "bloodDonationFirstAid": "Blood donation and first aid",
      "procedure": "Need to submit to selection methods in a competitive bidding procedure",
      "electedWorkers": "Workers elected to collective representation structures",
      "publicOfficeCandidate": "Candidates for public office during the legal period of the election campaign",
      "prenatalConsultations": "Exemption for prenatal consultations",
      "timeCreditUse": "Use of time credit from the previous month",
      "externalService": "External service on the day(s)",
      "occasionalInterruptions": "Occasional interruptions",
      "annualCredit": "Annual Credit",
      "other": "Other absences foreseen in the law"
    },
    "status": {
      "label": "Status",
      "draft": "Draft",
      "submitted": "Submitted",
      "approved": "Approved",
      "evaluated": "Evaluated",
      "denied": "Rejected",
      "reviewed": "Reviewed",
      "manager_reviewed": "Reviewed by manager",
      "closed": "Closed"
    }
  }
}
</i18n>
